import React from "react"
import { Layout } from "../components/index"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

export default function BlogPage() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "DD. MM. YYYYr.")
            description
          }
        }
      }
    }
  `)
  interface MarkdownInterface {
    title: string
    data: string
    node: any
    description: string
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "blog",
        }}
      />
      <h1>Blog test2</h1>
      <ol>
        {data.allContentfulBlogPost.edges.map(
          (edge: MarkdownInterface, index: number) => {
            return (
              <li key={index}>
                <Link to={`/blog/${edge.node.slug}`}>
                  <h2>{edge.node.title}</h2>
                </Link>
                <p>{edge.node.publishedDate}</p>
                <p>{edge.node.description}</p>
              </li>
            )
          }
        )}
      </ol>
    </Layout>
  )
}
